import { useCallback, useEffect, useRef, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import Webcam from "react-webcam";
import { useQuery } from "../../hook/useQuery";

const FormExit = (props) => {
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [msg, setMsg] = useState(null);
  const webcamRef = useRef(null);
  const query = useQuery();
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      window.location.href = "./home";
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 1500);
  }, [msg]);

  const videoConstraints = {
    width: { min: 720 },
    height: { min: 480 },
    facingMode: "user",
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const onSubmit = (data) => {
    if (imageSrc === null) {
      return;
    }

    var file = dataURLtoFile(imageSrc, "photo.png");

    if (loading) return;

    setLoading(true);
    const payload = {
      id_gate: query.get("id_gate"),
      rfid: data.rfid,
      photo: file,
    };

    console.log(payload);

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/rfidx/exit", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            reset();
            setImageSrc(null);
            setMsg({
              success: true,
              msg: "Success Checkout",
            });
          } else {
            setMsg({
              success: false,
              msg: "Failed Checkout",
            });
          }
        })
        .catch(function (error) {
          setLoading(false);
          setMsg({
            success: false,
            msg: "Failed Checkout",
          });
        });
    } catch (error) {
      setLoading(false);
      setMsg({
        success: false,
        msg: "Failed Checkout",
      });
    }
  };

  const capture = useCallback(() => {
    setImageSrc(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const retake = () => {
    setImageSrc(null);
  };

  const canSubmit = () => {
    if (watch("rfid") !== "" && imageSrc !== null) {
      return true;
    } else {
      return false;
    }
  };

  const validateRFID = (value) => {
    if (value.length === 10) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-3 px-5 py-5 bg-white rounded-md w-full md:w-1/3"
      >
        <div className="text-center font-bold mb-5">
          <div className="text-2xl">CHECKOUT</div>
        </div>

        {msg !== null && msg?.success && (
          <>
            <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
              CHECKOUT SUCCESS
            </div>
          </>
        )}

        {msg !== null && !msg?.success && (
          <div className="text-center font-bold text-white bg-red-500 py-2 rounded">
            {msg?.msg}
          </div>
        )}

        {(msg === null || !msg?.success) && (
          <>
            <div className="flex flex-col">
              <div className="flex justify-between mb-1">
                <label className="text-black w-full font-bold">
                  SCAN NEW RFID
                </label>
                <button
                  type="button"
                  onClick={() => reset()}
                  className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
                >
                  Clear
                </button>
              </div>

              <input
                autoComplete="off"
                role="presentation"
                autoFocus
                className="border-2 px-2 h-[38px] rounded"
                {...register("rfid", {
                  required: true,
                  validate: validateRFID,
                })}
              />

              {errors.rfid && (
                <span className="text-red-500 text-sm">
                  RFID must consist of 10 characters
                </span>
              )}
            </div>

            <div className="relative shadow-lg drop-shadow-lg overflow-hidden">
              <div className={`${imageSrc !== null ? "invisible" : "visible"}`}>
                <Webcam
                  audio={false}
                  height={600}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={800}
                  videoConstraints={videoConstraints}
                  screenshotQuality={1}
                  imageSmoothing={true}
                  mirrored
                />
              </div>

              {imageSrc !== null && (
                <img
                  alt=""
                  src={imageSrc}
                  className={`${
                    imageSrc !== null ? "block" : "hidden"
                  } w-full h-full object-contain absolute top-0 left-0`}
                />
              )}
            </div>

            <div className="flex justify-center items-center">
              {imageSrc === null ? (
                <button
                  onClick={() => capture()}
                  type="button"
                  className={`cursor-pointer w-max px-4 py-1 rounded-lg text-white font-bold bg-blue-500  text-center`}
                >
                  Capture
                </button>
              ) : (
                <button
                  onClick={() => retake()}
                  type="button"
                  className={`cursor-pointer w-max px-4 py-1 rounded-lg text-white font-bold bg-orange-500  text-center`}
                >
                  Retake
                </button>
              )}
            </div>

            <hr />

            <div className="flex space-x-5">
              {canSubmit() ? (
                <button
                  type="submit"
                  className={` min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
                >
                  {loading ? "Loading..." : "Confirm"}
                </button>
              ) : (
                <button
                  className={`cursor-not-allowed min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-gray-500  w-full text-center`}
                >
                  Confirm
                </button>
              )}
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default FormExit;
